<template>
    <div class="customizer">
        <div v-if="(!isProduction && !isVadobay) || isDevelopment" :class="'settings ' + (showPanel ? 'showSettings' : '')">
            <div class="settings--wrapper">
                <div class="settings--toggle" @click="toggleOptions()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                        <path d="M0 0h48v48h-48z" fill="none"/>
                        <path custom-fill-primary d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97l-.75-5.3c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97l-4.98-2.01c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31c-.08.64-.14 1.29-.14 1.95s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zm-14.86 5.05c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
                    </svg>
                </div>
                
                <div :class="'settings--panel ' + (this.showPicker ? 'showPicker' : '')">
                    <div class="picker">
                        <Sketch
                            v-model="model"
                            @input="(value) => { colorPicked(value.hex) }"
                            :preset-colors="[]"
                        />
                    </div>

                    <b-button class="mr-2" :variant="customizeOn ? 'success' : 'light'" size="sm" @click="toggleBodyCustomized()">
                        customize mode {{customizeOn ? 'ON' : 'OFF'}}
                    </b-button>

                    <b-button size="sm" variant="info" v-b-modal.modal-export @click="toggleOptions()">Esporta schema colori</b-button>

                    <b-modal id="modal-export" title="Esporta schema colori" size="md" hide-footer>
                        <div class="export-string">
                        <p><pre>{{colors | pretty}}</pre></p>
                        </div>
                    </b-modal>

                    <div class="colors">
                        <div class="colors--tab">
                            <strong>brand</strong>
                            <ul>
                                <li @click="togglePicker('primary', primary)">
                                    <span class="title">primary</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['primary'] : defaultColors['primary'])"></span>
                                </li>
                                <li @click="togglePicker('secondary', secondary)">
                                    <span class="title">secondary</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['secondary'] : defaultColors['secondary'])"></span>
                                </li>
                                <li @click="togglePicker('tertiary', tertiary)">
                                    <span class="title">tertiary</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['tertiary'] : defaultColors['tertiary'])"></span>
                                </li>
                            </ul>
                        </div>
                        <div class="colors--tab">
                            <strong>gradients</strong>
                            <ul>
                                <li @click="togglePicker('gradientBrandFirst', gradientBrandFirst)">
                                    <span class="title">gradient Brand First</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['gradientBrandFirst'] : defaultColors['gradientBrandFirst'])"></span>
                                </li>
                                <li @click="togglePicker('gradientBrandLast', gradientBrandLast)">
                                    <span class="title">gradient Brand Last</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['gradientBrandLast'] : defaultColors['gradientBrandLast'])"></span>
                                </li>
                                <li @click="togglePicker('gradientCommonFirst', gradientCommonFirst)">
                                    <span class="title">gradient common First</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['gradientCommonFirst'] : defaultColors['gradientCommonFirst'])"></span>
                                </li>
                                <li @click="togglePicker('gradientCommonLast', gradientCommonLast)">
                                    <span class="title">gradient Common Last</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['gradientCommonLast'] : defaultColors['gradientCommonLast'])"></span>
                                </li>
                                <li @click="togglePicker('textOverGradient', textOverGradient)">
                                    <span class="title">textOverGradient</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['textOverGradient'] : defaultColors['textOverGradient'])"></span>
                                </li>
                            </ul>
                        </div>
                        <div class="colors--tab">
                            <strong>utilities</strong>
                            <ul>
                                <li @click="togglePicker('success', success)">
                                    <span class="title">success</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['success'] : defaultColors['success'])"></span>
                                </li>
                                <li @click="togglePicker('info', info)">
                                    <span class="title">info</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['info'] : defaultColors['info'])"></span>
                                </li>
                                <li @click="togglePicker('warning', warning)">
                                    <span class="title">warning</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['warning'] : defaultColors['warning'])"></span>
                                </li>
                                <li @click="togglePicker('danger', danger)">
                                    <span class="title">danger</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['danger'] : defaultColors['danger'])"></span>
                                </li>
                            </ul>
                        </div>
                        <div class="colors--tab">
                            <strong>tipografia</strong>
                            <ul>
                                <li @click="togglePicker('text', text)">
                                    <span class="title">text default</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['text'] : defaultColors['text'])"></span>
                                </li>
                                <!--
                                <li @click="togglePicker('light', light)">
                                    <span class="title">text light</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['light'] : defaultColors['light'])"></span>
                                </li>
                                <li @click="togglePicker('dark', dark)">
                                    <span class="title">text dark</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['dark'] : defaultColors['dark'])"></span>
                                </li>-->
                                <li @click="togglePicker('cta', cta)">
                                    <span class="title">cta</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['cta'] : defaultColors['cta'])"></span>
                                </li>
                            </ul>
                        </div>


                        <div class="colors--tab">
                            <strong>footer</strong>
                            <ul>
                                <li @click="togglePicker('footerFirstColor', footerFirstColor)">
                                    <span class="title">footerFirstColor</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['footerFirstColor'] : defaultColors['footerFirstColor'])"></span>
                                </li>
                                <li @click="togglePicker('footerLastColor', footerLastColor)">
                                    <span class="title">footerLastColor</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['footerLastColor'] : defaultColors['footerLastColor'])"></span>
                                </li>
                                <li @click="togglePicker('footerTextColor', footerTextColor)">
                                    <span class="title">footerTextColor</span>
                                    <span class="preview" :style="'background-color:'+(customizeOn ? colors['footerTextColor'] : defaultColors['footerTextColor'])"></span>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <v-style type="text/css" :v-if="config !== undefined">
            :root {
                --custom-primary: {{primary}};
                --custom-secondary: {{secondary}};
                --custom-tertiary: {{tertiary}};

                --custom-gradientBrandFirst: {{gradientBrandFirst}};
                --custom-gradientBrandLast: {{gradientBrandLast}};
                --custom-gradientCommonFirst: {{gradientCommonFirst}};
                --custom-gradientCommonLast: {{gradientCommonLast}};

                --custom-success: {{success}};
                --custom-info: {{info}};
                --custom-warning: {{warning}};
                --custom-danger: {{danger}};

                --custom-text: {{text}};
                <!--
                --custom-light: {{light}};
                --custom-dark: {{dark}};
                --custom-white: {{white}};
                --custom-black: {{black}};
                -->

                --custom-cta: {{cta}};

                --custom-footerFirstColor: {{footerFirstColor}};
                --custom-footerLastColor: {{footerLastColor}};
                --custom-footerTextColor: {{footerTextColor}};

                --custom-textOverGradient: {{textOverGradient}};
            }
        </v-style>
    </div>
</template>

<script>
    import { Sketch } from 'vue-color'
    export default {
        name: 'customize',
        components:
        {
            Sketch,
        },
        data() {
            return {
                isDevelopment: (process.env.VUE_APP_MODE == 'development'),
                isProduction: (process.env.VUE_APP_MODE == 'production'),
                isVadobay: (process.env.VUE_APP_NAME == 'vadobay'),
                config: {},
                showPanel: false,
                colors: {},
                defaultColors: {},
                customizeOn: false,
                model: '',
                showPicker: false,
                openPickerColor: '',

                primary: '',
                secondary: '',
                tertiary: '',

                gradientBrandFirst: '',
                gradientBrandLast: '',
                gradientCommonFirst: '',
                gradientCommonLast: '',

                success: '',
                info: '',
                warning: '',
                danger: '',

                text: '',
                //light: '',
                //dark: '',
                white: '',
                //black: '',

                cta: '',

                footerFirstColor: '',
                footerLastColor: '',
                footerTextColor: '',

                textOverGradient: '',
            }
        },
        methods:
        {
            toggleOptions()
            {
                this.showPanel = !this.showPanel
            },
            togglePicker(name, color)
            {
                if(!this.customizeOn) return

                this.openPickerColor = name
                this.model = color
                if(this.showPicker) return
                this.showPicker = true
            },
            colorPicked(color)
            {
                this[this.openPickerColor] = color
                this.colors[this.openPickerColor] = color
            },
            
            toggleBodyCustomized(){
                (document.body.classList).toggle('customized')
                this.customizeOn = !this.customizeOn
                if(!this.customizeOn) this.showPicker = false
            },
            
        },
        mounted() {
            this.config = this.appConfig()

            if (this.config.guiSettings.customizations !== undefined && this.config.guiSettings.customizations)
            {
                document.body.classList.add('customized')

                this.customizeOn = true

                let c = this.config.guiSettings.customizations

                if (c.colors !== undefined)
                {
                    let colors = {},
                        defaultColors = {}

                    // i colori di fallback si trovano dentro la variabile $theme-colors in _custom.scss

                    // colori dominanti
                    let primary = '#F86D51' //$primary
                    this.primary = c.colors.primary ? c.colors.primary : primary
                    colors.primary = this.primary
                    defaultColors.primary = primary

                    let secondary = '#FDC500' //$secondary
                    this.secondary = c.colors.secondary ? c.colors.secondary : secondary
                    colors.secondary = this.secondary
                    defaultColors.secondary = secondary

                    let tertiary = '#39414E' //$tertiary
                    this.tertiary = c.colors.tertiary ? c.colors.tertiary : tertiary
                    colors.tertiary = this.tertiary
                    defaultColors.tertiary = tertiary

                    
                    // gradienti
                    let gradientBrandFirst = '#ffffff'
                    this.gradientBrandFirst = c.colors.gradientBrandFirst ? c.colors.gradientBrandFirst : gradientBrandFirst
                    colors.gradientBrandFirst = this.gradientBrandFirst
                    defaultColors.gradientBrandFirst = gradientBrandFirst

                    let gradientBrandLast = '#ffffff'
                    this.gradientBrandLast = c.colors.gradientBrandLast ? c.colors.gradientBrandLast : gradientBrandLast
                    colors.gradientBrandLast = this.gradientBrandLast
                    defaultColors.gradientBrandLast = gradientBrandLast

                    let gradientCommonFirst = '#ffffff'
                    this.gradientCommonFirst = c.colors.gradientCommonFirst ? c.colors.gradientCommonFirst : gradientCommonFirst
                    colors.gradientCommonFirst = this.gradientCommonFirst
                    defaultColors.gradientCommonFirst = gradientCommonFirst

                    let gradientCommonLast = '#ffffff'
                    this.gradientCommonLast = c.colors.gradientCommonLast ? c.colors.gradientCommonLast : gradientCommonLast
                    colors.gradientCommonLast = this.gradientCommonLast
                    defaultColors.gradientCommonLast = gradientCommonLast
    
                    // colori utilities
                    let success = '#28a745' //$success
                    this.success = c.colors.success ? c.colors.success : success
                    colors.success = this.success
                    defaultColors.success = success

                    let info = '#17a2b8' //$info
                    this.info = c.colors.info ? c.colors.info : info
                    colors.info = this.info
                    defaultColors.info = info

                    let warning = '#ffc107' //$warning
                    this.warning = c.colors.warning ? c.colors.warning : warning
                    colors.warning = this.warning
                    defaultColors.warning = warning

                    let danger = '#dc3545' //$danger
                    this.danger = c.colors.danger ? c.colors.danger : '#dc3545' //$danger
                    colors.danger = this.danger
                    defaultColors.danger = danger

                    // testi
                    let text = '#212529' //$body-color
                    this.text = c.colors.text ? c.colors.text : text
                    colors.text = this.text
                    defaultColors.text = text

                    /*
                    let light = '#f8f9fa' //$light
                    this.light = c.colors.light ? c.colors.light : light
                    colors.light = this.light
                    defaultColors.light = light

                    let dark = '#343a40' //$dark
                    this.dark = c.colors.dark ? c.colors.dark : dark
                    colors.dark = this.dark
                    defaultColors.dark = dark

                    let white = '#ffffff' //$white
                    this.white = c.colors.white ? c.colors.white : white
                    colors.white = this.white
                    defaultColors.white = white

                    let black = '#000000' //$black
                    this.black = c.colors.black ? c.colors.black : black
                    colors.black = this.black
                    defaultColors.black = black
                    */

                    let cta = '#157FFB' //$cta
                    this.cta = c.colors.cta ? c.colors.cta : cta
                    colors.cta = this.cta
                    defaultColors.cta = cta

                    let footerFirstColor = '#ffffff'
                    this.footerFirstColor = c.colors.footerFirstColor ? c.colors.footerFirstColor : footerFirstColor
                    colors.footerFirstColor = this.footerFirstColor
                    defaultColors.footerFirstColor = footerFirstColor

                    let footerLastColor = '#ffffff'
                    this.footerLastColor = c.colors.footerLastColor ? c.colors.footerLastColor : footerLastColor
                    colors.footerLastColor = this.footerLastColor
                    defaultColors.footerLastColor = footerLastColor

                    let footerTextColor = '#000000'
                    this.footerTextColor = c.colors.footerTextColor ? c.colors.footerTextColor : footerTextColor
                    colors.footerTextColor = this.footerTextColor
                    defaultColors.footerTextColor = footerTextColor

                    let textOverGradient = '#ffffff'
                    this.textOverGradient = c.colors.textOverGradient ? c.colors.textOverGradient : textOverGradient
                    colors.textOverGradient = this.textOverGradient
                    defaultColors.textOverGradient = textOverGradient

                    this.colors = colors
                    this.defaultColors = defaultColors

                    Object.keys(colors).forEach(key => {
                      (document.body.classList).add(key+'-'+colors[key].replace('#',''))
                    })
                }
            
            }
        }
    }
</script>

<style lang="scss" scoped>

    $toggle-size: 40px;
    $picker-size: 260px;
    $background: #fff;
    $border: #ccc;
    $border-dark: #ccc;

    .settings
    {
        color:#313131;
        position:fixed;
        top:40%;
        right:0;
        z-index: 999999;
        transform:translateX(calc(100% - #{$toggle-size} - 1rem));
        transition: all 0.5s ease-in-out;

        &.showSettings
        {
            transform:translateX(0px);
        }

        button
        {
            text-transform: uppercase;
            margin:0 auto;
        }

        &--wrapper
        {
            display: flex;
        }

        &--toggle
        {
            background: $background;
            width:$toggle-size;
            height:$toggle-size;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            cursor:pointer;
            border-left:1px solid $border-dark;
            border-top:1px solid $border-dark;
            border-bottom:1px solid $border-dark;
            margin-right:-1px;
            margin-top:-1px;
            position:relative;
            z-index: 1;
            padding:6px;
        }

        &--panel
        {
            padding:20px;
            min-width:220px;
            //min-height:200px;
            background: $background;
            position:relative;
            transition: all 0.75s ease-in-out;
            //border:1px solid $border-dark;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            box-shadow: 0 0 0 1px rgb(0 0 0 / 15%), 0 8px 16px rgb(0 0 0 / 15%);

            &.showPicker
            {
                margin-right: $picker-size;
            }
            .picker
            {
                position:absolute;
                top:0;
                right:0;
                transform: translateX($picker-size);
                padding:0 20px;
            }
            .colors
            {
                display: flex;
                margin-top:20px;
                strong
                {
                    display: block;
                    font-size: 12px;
                    margin-bottom: 12px;
                }
                &--tab
                {
                    &:not(:last-child)
                    {
                        margin-right:20px;
                    }
                }
            }
            ul
            {
                flex:1;
                //min-width:120px;
            }
            li
            {
                line-height: 1;
                display:flex;
                align-items: center;
                margin-bottom: 12px;
                padding: 6px 6px 6px 10px;
                border: 1px solid $border;
                border-radius: 5px;
                &:first-child
                {
                }
                &:last-child
                {
                }
                cursor: pointer;
                .title
                {
                    flex:1;
                    white-space: nowrap;
                    font-size: 14px;
                    min-width: 100px;
                }
                .preview
                {
                    width:20px;
                    height:20px;
                    border: 1px solid $border;
                }
            }
        }
    }
</style>


<style lang="scss">
    .export-string
    {
        display: flex;
        align-items: center;
        justify-content:center;
        padding:20px;
    }

    // eccezioni per conflitti colore

    // hotels-com
    body.secondary-FFFFFF {
      .btn-secondary:not(.disabled),  // btn-secondary bianco su fondo bianco
      .custom-checkbox ~ label *      // checkbox terms/privacy bianca su fondo grigio
      {
        color: var(--textcustom-text) !important;
      }
    }
</style>