import Router from './router'
import Store from './vuex/store'
import Api from './api'
import { number, larger, round, string } from 'mathjs'

export default {
    /*
    created() {
        //window.console.log('Utilities Mixin Creted')
    },
    */
    methods: {
        mixinSortResults: function(data,key,order)
        {
            if(this.$router.history.current.name == 'map-list')
            {
                this.mixinSendMutation('setScroll',0)
            }
            let results = data.sort(function(a, b) {
                return a[key] - b[key];
            })
            if(order == 'DESC')
            {
                results = results.reverse()
            }
            return results
        },
        mixinLogout: function()
        {
            if(window.LogRocket) window.LogRocket.track('logout')
            return Api.logout()
        },
        mixinUseTicketCompliments: function()
        {
            return this.$config.guiSettings.integrations.etc.enabled
        },
        mixinIsAuthenticated: function()
        {
            let token = Store.state.token
            if(Router.history.current.query.token) token = Router.history.current.query.token
            if(!token) return false
            if(!Store.state.integration || !Store.state.integration.integration) return false
            return true
        },
        mixinIsPublic: function()
        {
          if(Store.state.integration && Store.state.integration.integration == 'ambalagi') return true
          if(Store.state.integration && Store.state.integration.integration == 'vivamod') return true
          return false
        },
        mixinSendMutation: function(type,value)
        {
            Store.commit({
                type: type,
                value: value,
            })
        },
        mixinGoTo: function(routeName,routeParameters)
        {
            if(routeParameters.newWindow)
            {
                //let store = window.btoa((JSON.stringify(Store.state))).replace(/[^0-9a-z]/gi, '')
                let routeData = Router.resolve({
                    name: routeName,
                    params: routeParameters,
                    query:{
                        //state: store
                    }
                });
                window.open(routeData.href, '_blank')
            }
            else
            {
                Router.push({
                        name: routeName,
                        params: routeParameters
                    }).catch(() => {
                })
            }
        },
        mixinRoundTwoDigits: function(value)
        {

            if((typeof value == 'string')) value = value.replace('.00', ''); // SyntaxError: String "620.46153.00" is no valid number

            let temp = number(value)

            if(temp <= 0) return value // availability può essere -1
            //return ((Math.round(value * 100) / 100).toFixed(2))*100/100

            let x= round(temp, 2)
            return (typeof value == 'string')? string(x) : x
        },
        mixinValidateFields: function(refs)
        {
            let fieldCheck = true,
                fieldError = '',
                formCheck = true

            refs.forEach(ref => {

                if(ref.optional && ref.value == '') return

                switch (ref.type) {
                    case 'discountCode':
                        fieldCheck = ((ref.value).length !== 16) ? false : true
                        fieldError = 'codice buono non valido'
                    break;

                    case 'address':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'questo indirizzo non è valido'
                    break;
                    
                    case 'city':
                        fieldCheck = ((ref.value).length < 3 | !(/^[a-zA-Z 'àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$/.test(ref.value))) ? false : true
                        fieldError = 'questa città non è valida'
                    break;
                    
                    case 'postalCode':
                        fieldCheck = ((ref.value).length !== 5) ? false : true
                        fieldError = 'il CAP non è valido'
                    break;
                    
                    case 'province':
                        fieldCheck = ((ref.value).length !== 2) ? false : true
                        fieldError = 'la sigla della provincia deve essere formata da due lettere'
                    break;

                    case 'fiscalCode':
                        fieldCheck = ((ref.value).length !== 16) ? false : true
                        fieldError = 'completa o verifica la correttezza del codice'
                    break;

                    case 'email':
                        fieldCheck = (/\S+@\S+\.\S+/.test(ref.value)) ? true : false
                        fieldError = 'questo indirizzo email non è valido'
                    break;

                    case 'emailConfirm':
                        fieldCheck = ref.value
                        fieldError = 'la mail di conferma è diversa da quella inserita'
                    break;

                    case 'firstName':
                        fieldCheck = ((ref.value).length < 2) ? false : true
                        fieldError = 'il nome deve essere composto da almeno due caratteri'
                    break;

                    case 'lastName':
                        fieldCheck = ((ref.value).length < 2) ? false : true
                        fieldError = 'il cognome deve essere composto da almeno due caratteri'
                    break;

                    case 'phone':
                        fieldCheck = ((ref.value).length < 4) ? false : true
                        fieldError = 'numero di telefono non valido'
                    break;

                    case 'checkbox':
                        fieldCheck = (ref.value == true)
                        fieldError = 'questo campo è obbligatorio'
                    break;

                    case 'textarea':
                      fieldCheck = ((ref.value).length < 4) ? false : true
                      fieldError = 'questo campo non può essere vuoto'
                      break;

                    case 'otp':
                        fieldCheck = ((ref.value).length !== 6) ? false : true
                        fieldError = 'Il campo OTP deve contenere 6 caratteri'
                    break;

                    default:
                        fieldCheck = false
                        fieldError = ''
                    break;
                }
                
                if(ref.ref !== undefined)
                {
                    if(fieldCheck)
                    {
                        ref.ref.$el.classList.remove('invalid')
                        fieldError = ''
                    }
                    else
                    {
                        if(ref.value !== '') ref.ref.$el.classList.add('invalid')
                    }

                    if(ref.ref.$el.nextSibling && ref.ref.$el.nextSibling.classList.contains('formError'))
                    {
                        if(ref.value !== '') ref.ref.$el.nextSibling.innerHTML = fieldError
                    }
                }

                formCheck = formCheck && fieldCheck
            });

            return formCheck
        },
        mixinIsWelfare()
        {
            if(this.$config.integration) return this.$config.integration.welfare
            return true;
        },
        mixinHasAvailability()
        {
            return (this.$config.integration.availability >= 0)
        },        
        // utilities per flag welfare
        mixinCanUseCc()
        {
            /* TBA: implementare successivamente */
        },
        mixinGetIntegrationInfo(livePrice)
        {
            let availability = this.$config.integration.availability,
                integration = this.$config.integration.integration

            if ( !this.mixinIsPublic() && this.mixinHasAvailability() && (larger(number(livePrice), number(availability))) )
            {
                if( (!this.mixinIsWelfare()) || (integration == 'jointly') )
                {
                    return {
                        class: 'gold',
                        canCheckout: true,
                        integration: integration,
                    }
                }
                return {
                    class: 'red',
                    canCheckout: false,
                    integration: integration,
                }
            }
            
            return  {
                class: 'default', //blue
                canCheckout: true,
                integration: integration,
            }
        },
        mixinCanBillToPax()
        {
            return this.$config.integration.billToPax
        },

        mixinShowNonRefundableModal()
        {

          let showNonRefundableModal = false; // TODO legare a config.guiSettings.showNonRefundableModal (non ancora disponibile)

          if(showNonRefundableModal){

            if(process.env.VUE_APP_MODE != 'production') window.console.log('trackNonRefundableModal: opened');

            if(this.$gtm) this.$gtm.trackEvent({
              event: 'NonRefundableModal',
              category: 'userBehaviour',
              action: 'opened',
              label: '',
              value: 0,
            });

            return true; // fa mostrare la modal
          }

          return false; // ignora la modal, continua con azione di default
        },
    },
}