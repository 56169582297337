import Vue from 'vue'
import Axios from 'axios'
import Store from './vuex/store'
import Router from './router'

export default {
    configUrl: process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_CONFIG_API,
    endpoint: '',
    endpoints: {},

    useMocks: false,
    endpointsMock:{
      getPreview: '',
      getPropertySuggestions: '',
      getReviews: '',
      getDetails: '',
    },

    sendNotification: function(error)
    {
        let callback = function() {
            return window.console.log('Nessuna azione prevista')
        }


        if(error.response.status != 200 && error.response.status != 511){

          let dataLayer = (window.dataLayer = window.dataLayer || [])
          dataLayer.push({
            event:               'customEvent',
            target:              'modalNotifications', // category
            action:              (error.response.status)? 'http_' + error.response.status : 'http_xxx',
            'target-properties': (error.response.data.msg)? error.response.data.msg : '',
            value:               (error.response.data.eventId)? error.response.data.eventId : 0,
            'interaction-type':  false,
          })
          
          if(window.LogRocket) window.LogRocket.track('errorHttp_' + error.response.status)
          if(window.clarity) window.clarity("set", "errorHttp", error.response.status)

        } 
        
        
        switch (error.response.status) {

            // aggiornare affiliateReferenceId
            case 300:
                // gestito a livello di componente sidebar-checkout.vue
                // TODO (michele) return error.response (e correggere su sidebar-checkout.vue) 
                return;

            // errore "pilotabile" da backend
            // posso mostrare la sola modal, gestire un redirect o un'azione
            case 303:
            case 202: // ie del cazzo
            case 555:

              if(error.response.data.action){

                switch(error.response.data.action){
                  case 'logout':
                    Store.replaceState({})
                    Vue.prototype.$config.integration = {}
                    break;

                  default:
                }

              }


              if(error.response.data.updateAvailabilityTo){
                Store.commit({
                  type: 'setAvailability',
                  value: error.response.data.updateAvailabilityTo,
                })
              }


              if(error.response.data.routerPush){
                callback = function() {
                  Router.push({name: error.response.data.routerPush})
                }
              }

              if(error.response.data.routerPushPath){
                callback = function() {
                  Router.push(error.response.data.routerPushPath)
                }
              }

              if(error.response.data.routerPushError){
                callback = function() {
                  Router.push({name: 'error', query: {error: btoa(error.response.data.routerPushError)}})
                }
              }


              if(error.response.data.redirectTo){
                callback = function() {
                  window.console.log('redirectTo')
                  window.console.log(error.response.data.redirectTo)
                  window.location.href = error.response.data.redirectTo
                }
              }

              break;

            // maintenance mode
            case 307:
            case 503:
                Store.replaceState({})
                Vue.prototype.$config.integration = {}
                return Router.push({name: 'maintenance'})
                /*
                callback = function() {
                    Router.push({name: 'maintenance'})
                }
                break;
                */

            // token non valido / sessione scaduta rimandare a pagina come funziona
            case 401:
                Store.replaceState({})
                Vue.prototype.$config.integration = {}
                
                
                if(process.env.VUE_APP_NAME != 'ambalagi'){
                    
                    callback = function() {
                        Router.push({name: 'how-it-works'})
                    }
                    
                }else{
                    
                    callback = function() {
                      
                      window.location.href = '/'
                      
                    }
                    
                }
                
                
                break;

            // il prezzo è cambiato, rimandare a dettaglio
            case 409:
                callback = function() {
                    //Router.go(-1)//push({name: 'search'})
                    Router.back()
                }
                break;

                    
            case 501:
              callback = function() {
                  Router.push({name: 'search'})
              }
              break;
              
              
            // errore silente (es. reviews)
            // logga in console ma non segnala nulla all'utente
            case 511:
              window.console.log('http 511 errore silente')
              window.console.log(error.response)
              return false
              //break;

            case 500: // recuperabile
            case 400: // recuperabile
            case 404: // recuperabile
              break;

            default:
              break;
        }


        let data = error.response.data

        if(data == ''){
          // errore non gestito lato server (es. PHP FATAL ERROR)
          data = {}
          callback = function() {
            Router.push({name: 'homepage'})
          }
        }

        data.status = (error.response.status)? error.response.status : ''
        data.callback = callback

        window.eventBus.$emit('gotNotification',data)
        return error.response
    },
    getNotification: function(queryParameters)//test http errors
    {
        let params = JSON.stringify({
            testHttpStatusCode: queryParameters.httpCode
        })
        return Axios.post(this.endpoint + 'testNotifications.php', params)
        .then(() => {
            return 'No errors!';
        }, (error) => {
            return this.sendNotification(error)
        })
    },
    getConfig: function()
    {
        let token = '',
            customDesignCode = '',
            _this = this

        if(Router.history.current.query.token) token = Router.history.current.query.token

        if(Router.history.current.name == 'custom-landing-dc') customDesignCode = Router.history.current.params.customDesignCode

        if (token == '') token = Store.state.token

        let params = JSON.stringify({
            token: token,
            routeName: Router.history.current.name,
            customDesignCode: customDesignCode,
        })

        return Axios.post(this.configUrl, params)
            .then((response) => {
                _this.endpoint = response.data.endpoint
                _this.endpoints = response.data.endpoints
                return response.data;
            }, (error) => {
                window.console.log(error)
                return this.sendNotification(error)
            })
    },
    getPreview: function(queryParameters)
    {
        let params = JSON.stringify({
            checkin: queryParameters.checkin,
            checkout: queryParameters.checkout,
            //nights: queryParameters.nights,
            occupancy: queryParameters.occupancy,
            lat: queryParameters.lat,
            lng: queryParameters.lng,
            offset: queryParameters.offset,
            matchLevel: queryParameters.matchLevel,
            geoLabel: queryParameters.label,
            searchCounter: queryParameters.searchCounter,
            debugString: queryParameters.debugString,
            salabamCategories: queryParameters.salabamCategories,
            token: Store.state.token,
            routeName: Router.history.current.name,
            inSalabamId: (queryParameters.inSalabamId)? queryParameters.inSalabamId : 0,
            property_id: (queryParameters.property_id)? queryParameters.property_id : 0,
            limit: (queryParameters.limit)? queryParameters.limit : 0,
            geography: (queryParameters.geography)? queryParameters.geography : '',
            inSalabamSuggestion: (queryParameters.inSalabamSuggestion)? queryParameters.inSalabamSuggestion : {},
            properties_ids: queryParameters.properties_ids,
        })

        let _this = this

        let endpoint = this.endpoints.preview;
        if(this.useMocks && this.endpointsMock['getPreview']) endpoint = this.endpointsMock['getPreview']

        return Axios.post(endpoint, params)
        .then((response) => {
            if (response.status !== 200) return false

            // gestisco il caso in cui il servizio risponde 200 ma non è presente un json
            if(!response.data){
              return this.sendNotification({
                response: {
                  status: 555,
                  data: {
                    msg: 'Si è verificato un problema, prova a ripetere la ricerca o ricaricare la pagina',
                  }
                }
              })
            }

            // supporto per risposte codificate (per nascondere prezzi netti)
            if(typeof(response.data.results) == 'string') response.data.results = JSON.parse(atob(response.data.results))

            // Vadobay si aspetta come .results un array
            if(typeof(response.data.results) !== 'object' || !Array.isArray(response.data.results))
            {
              return _this.sendNotification({
                response: {
                  status: 555,
                  data: {
                    msg: 'Si è verificato un problema, ricarica la pagina per continuare (invalid preview response)',
                    redirectTo: '/homepage',
                  }
                }
              })
            }

            return response.data;

        }, (error) => {
            window.console.log('getPreview error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getPropertySuggestions: function(queryParameters)
    {
        let params = JSON.stringify({
            checkin: queryParameters.checkin,
            checkout: queryParameters.checkout,
            //nights: queryParameters.nights,
            occupancy: queryParameters.occupancy,
            lat: queryParameters.lat,
            lng: queryParameters.lng,
            offset: queryParameters.offset,
            matchLevel: queryParameters.matchLevel,
            geoLabel: queryParameters.label,
            searchCounter: queryParameters.searchCounter,
            //debugString: queryParameters.debugString,
            //salabamCategories: queryParameters.salabamCategories,
            token: Store.state.token,
            routeName: Router.history.current.name,
            reference_property_id: queryParameters.reference_property_id,
            // testNoResults: 1, // forza api a rispondere results vuoto, per testing (solo in _debug)
        })

        let endpoint = this.endpoints.suggestions;
        if(this.useMocks && this.endpointsMock['getPropertySuggestions']) endpoint = this.endpointsMock['getPropertySuggestions']

        return Axios.post(endpoint, params)
        .then((response) => {
            if (response.status !== 200) return false
            /*
            // gestisco il caso in cui il servizio risponde 200 ma non è presente un json
            if(!response.data){
              return this.sendNotification({
                response: {
                  status: 555,
                  data: {
                    msg: 'Si è verificato un problema, prova a ripetere la ricerca o ricaricare la pagina',
                  }
                }
              })
            }
            */

            // supporto per risposte codificate (per nascondere prezzi netti)
            if(typeof(response.data.results) == 'string') response.data.results = JSON.parse(atob(response.data.results))

            return response.data;

        }, (error) => {
            window.console.log('getPreview error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getReviews: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)

        let endpoint = this.endpoints.reviews;
        if(this.useMocks && this.endpointsMock['getReviews']) endpoint = this.endpointsMock['getReviews']

        return Axios.post(endpoint, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getReviews error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getDetails: function(queryParameters)
    {
        let params = JSON.stringify({
            checkin: queryParameters.checkin,
            checkout: queryParameters.checkout,
            //nights: queryParameters.nights,
            occupancy: queryParameters.occupancy,
            property_id: queryParameters.propertyId,
            band: queryParameters.band,
            token: Store.state.token,
            routeName: Router.history.current.name,
            searchLogId: Store.state.searchLogId,
        })


        let endpoint = this.endpoints.details;
        if(this.useMocks && this.endpointsMock['getDetails']) endpoint = this.endpointsMock['getDetails']

        return Axios.post(endpoint, params)
        .then((response) => {

            // gestisco il caso in cui il servizio risponde 200 ma non è presente un json
            if(!response.data){
              return this.sendNotification({
                response: {
                  status: 555,
                  data: {
                    msg: 'Si è verificato un problema, prova a ripetere la ricerca o ricaricare la pagina',
                  }
                }
              })
            }

            // supporto per risposte codificate (per nascondere prezzi netti)
            if(typeof(response.data.availabilities) == 'string') response.data.availabilities = JSON.parse(atob(response.data.availabilities))

            return response.data;

        }, (error) => {
            window.console.log('getDetails error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getBeneficiary: function(queryParameters)
    {
        let params = JSON.stringify({
            token: queryParameters.token,
            routeName: queryParameters.routeName,
        })
        return Axios.post(this.endpoints.beneficiaries, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getBeneficiary error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    checkoutLivebooking: function(queryParameters)
    {
        queryParameters.routeName = Router.history.current.name
        queryParameters.usedCks = Store.state.cacheKeys
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)
    
        return Axios.post(this.endpoints.checkout, params)
        .then((response) => {
            if(response.status == 202) return this.sendNotification({response: response})
            return response;
        }, (error) => {
            window.console.log('checkoutLivebooking error')
            window.console.log(error)
            if(error.response.status == 300) return error.response // fix per update affiliateReferenceId
            return this.sendNotification(error)
        })
    },
    getReservation: function(queryParameters)
    {
        queryParameters.routeName = Router.history.current.name
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)

        return Axios.post(this.endpoints.conferma, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getReservation error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    cancelReservation: function(queryParameters)
    {
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)

        return Axios.post(this.endpoints.cancelReservation, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getReservation error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getAccessToken: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.etcGetAccessToken, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getAccessToken error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    jpSso: function(queryParameters)
    {
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.jpSso, params)
        .then((response) => {
          window.console.log(response)
          return {
            token: response.data.token
          }
        }, (error) => {
          return this.sendNotification(error)
        })
    },
    jpCheckToken: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.jpCheckToken, params)
        .then((response) => {
            window.console.log(response)
            return {
                check: true,
                data: response.data,
                tryAgain: false,
            }
        }, (error) => {

            if(error.response.status == 404)
            {
                return {
                    check: false,
                    results: error,
                    tryAgain: true,
                }
            }

            return this.sendNotification(error)
        })
    },
    scalapayConfirm: function(queryParameters)
    {
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.scalapay.confirm, params)
        .then((response) => {
          window.console.log(response)
          return response.data
        }, (error) => {
          return this.sendNotification(error)
        })
    },
    scalapayCancel: function(queryParameters)
    {
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.scalapay.cancel, params)
        .then((response) => {
          window.console.log(response)
          return response.data
        }, (error) => {
          return this.sendNotification(error)
        })
    },
    satispayConfirm: function(queryParameters)
    {
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.satispay.confirm, params)
        .then((response) => {
          window.console.log(response)
          return {
            check: true,
            data: response.data,
            tryAgain: false,
          }
        }, (error) => {

          if(error.response.status == 404)
          {
            return {
              check: false,
              results: error,
              tryAgain: true,
            }
          }

          return this.sendNotification(error)
        })
    },
    getPosts: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.posts, params)
        .then((response) => {
            // inverto l'ordine e trasformo in un array per compensare l'ordinamento interno di js quando viene parsato un oggetto con dentro altri oggetti
            let postKeys = Object.keys(response.data.posts).reverse()
            let postArray = []
            postKeys.forEach(key => {
                postArray.push(response.data.posts[key])
            })
            response.data.posts = postArray
            return response.data;
        }, (error) => {
            window.console.log('getPosts error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getTags: function(queryParameters)
    {
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.tags, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getTags error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getInSalabam: function(queryParameters)
    {
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.inSalabam, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getInSalabam error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    getBalances: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        window.console.log(params)
        return Axios.post(this.endpoints.etcGetBalances, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('getBalance error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    stripeCreateIntentClientSecret: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.stripeCreateIntentClientSecret, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('stripeCreateIntentClientSecret error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    otp: function(queryParameters)
    {
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)
        
        return Axios.post(this.endpoints.otp, params)
        .then((response) => {
          return response.data;
        }, (error) => {
            window.console.log('otp error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    dcLogin: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.dcLogin, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('dcLogin error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    dcCheck: function(queryParameters)
    {
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.dcCheck, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log('dcCheck error')
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    logout: function()
    {
      // il comportamento di default di questa api è di ritornare 303
      // per triggerare la modal "custom".. vedi sendNotification case 303
      let queryParameters = {}
      queryParameters.token = Store.state.token
      queryParameters.routeName = Router.history.current.name
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.logout, params)
        .then((response) => {
          return response.data
        }, (error) => {
          return this.sendNotification(error)
        })
    },
    getFaqs: function()
    {
      let queryParameters = {}
      queryParameters.token = Store.state.token
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.getFaqs, params)
        .then((response) => {
          return response.data
        }, (error) => {
          return this.sendNotification(error)
        })
    },
    getInSalabamMonographic: function(queryParameters)
    {
        queryParameters.token = Store.state.token
        let params = JSON.stringify(queryParameters)
        return Axios.post(this.endpoints.inSalabamMonographic, params)
        .then((response) => {
            return response.data;
        }, (error) => {
            window.console.log(error)
            return this.sendNotification(error)
        })
    },
    formsSubmitter: function(queryParameters)
    {
      queryParameters.token = Store.state.token
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.formsSubmitter, params)
        .then((response) => {
          return response.data
        }, (error) => {
          window.console.log('formsSubmitter error')
          window.console.log(error)
        })
    },
    doubleCheckInfo: function(queryParameters)
    {
      queryParameters.token = Store.state.token
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.doubleCheck.info, params)
        .then((response) => {
          return response.data
        }, (error) => {
          window.console.log('doubleCheckInfo error')
          window.console.log(error)
        })
    },
    doubleCheckConfirm: function(queryParameters)
    {
      queryParameters.token = Store.state.token
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.doubleCheck.confirm, params)
        .then((response) => {
          return response.data
        }, (error) => {
          window.console.log('doubleCheckConfirm error')
          window.console.log(error)
        })
    },
    doubleCheckSuggestion: function(queryParameters)
    {
      queryParameters.token = Store.state.token
      let params = JSON.stringify(queryParameters)
      return Axios.post(this.endpoints.doubleCheck.suggestion, params)
        .then((response) => {
          return response.data
        }, (error) => {
          window.console.log('doubleCheckSuggestion error')
          window.console.log(error)
        })
    },
    getFavorites: function()
    {
        window.console.log('[Api.js] - getFavorites --> asking favorites from API')
    },
    setFavorites: function()
    {
        window.console.log('[Api.js] - setFavorites --> sending favorites to API')
    },
    getHistory: function()
    {
        window.console.log('[Api.js] - getHistory --> asking History from API')
    },
    setHistory: function()
    {
        window.console.log('[Api.js] - setHistory --> sending History to API')
    },
    // Payments Link
    plRequest: function(endpoint, queryParameters) {
      let params = JSON.stringify(queryParameters)
      return Axios.post(endpoint, params)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          window.console.log(`${endpoint} error`)
          window.console.log(error)
          return this.sendNotification(error)
        })
    },

}