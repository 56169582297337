import Api from '@/api'
import Store from '@/vuex/store'
import Utilities from './utilities'

const MAX_FAVORITES = 20;

export default {
    setup: function()
    {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('[user.js] - setup')

        //predisposizione per api utente
        //this.getFavorites()
        //this.getHistory()
    },
    debug: function(message)
    {
        if(process.env.VUE_APP_MODE != 'production') window.console.log('[user.js] - ' + message)
    },
    getFavorites: function()
    {
        // di default viene caricato l'oggetto che trovo nel localstorage
        // la chiamata alle api dovrà restituire una promise che alla resolve dovrà fare lo Store.commit del risultato
        Api.getFavorites()
        /*
        Store.commit({
            type: 'setFavorites',
            value: {
                properties: []
            },
        })
        */
    },

    setFavorites: function(favorites)
    {
        //favorites.properties = favorites.properties.slice(0,MAX)

        let propertyIds = [],
            properties = favorites.properties

        properties.forEach(property => {
            propertyIds.push(property.property.propertyId)
        })

        favorites.propertyIds = propertyIds

        //Api.setFavorites(favorites)

        Store.commit({
            type: 'setFavorites',
            value: favorites,
        })
    },

    addToFavorites: function(payload)
    {
        let favorites = Store.state.favorites/*,
            favoriteProperties = favorites.properties,
            isFavorite = favoriteProperties.filter(function(property) {
                return property.property.propertyId == payload.value.propertyId
            }).length

        if(isFavorite !== 0) return*/

        favorites.properties.unshift({
            type: payload.type,
            property: payload.value,
            checkin: Store.state.dates.checkin,
            checkout: Store.state.dates.checkout,
            occupancy: Store.state.dates.occupancy,
            lat: Store.state.geo.latitude,
            lng: Store.state.geo.longitude,
            offset: 0,
            matchLevel: Store.state.geo.matchLevel,
            label: Store.state.geo.label,
            searchCounter: 0,
            ignoreMapRedirect: true, // https://trello.com/c/7oWYmzkB/855-invadobay-slider-monografico
        })

        favorites.properties = favorites.properties.slice(0, MAX_FAVORITES)

        this.setFavorites(favorites)
    },

    removeFromFavorites: function(payload)
    {
        let favorites = Store.state.favorites,
            favoriteProperties = favorites.properties,
            newFavoriteProperties = favoriteProperties.filter(function(property) {
                return property.property.propertyId !== payload.value.propertyId
            })

            //if(favoriteProperties.length == newFavoriteProperties.length) return
        
            this.setFavorites({properties:newFavoriteProperties})
    },

    checkIsFavorite(propertyId)
    {
        if(Store.state.favorites.propertyIds == undefined) return false

        return Store.state.favorites.propertyIds.includes(propertyId)

        /*
        let favorites = this.$store.state.favorites,
            _this = this

        if(favorites.properties == undefined)
        {
            this.favorite = false
            return
        }

        let findFavorite = favorites.properties.filter(function(property){
                return property.property.propertyId == _this.property.propertyId
            }).length
        
        this.favorite = (findFavorite !== 0)
        */
    },

    getHistory: function()
    {
        // di default viene caricato l'oggeto che trovo nel localstorage
        // la chiamata alle api dovrà restituire una promise che alla resolve dovrà fare lo Store.commit del risultato
        Api.getHistory()
        /*
        Store.commit({
            type: 'setHistory',
            value: {
                properties: []
            },
        })
        */
    },
    
    setHistory: function(history)
    {
        //history.properties = history.properties.slice(0,MAX)

        let propertyIds = [],
            properties = history.properties
        
        properties.forEach(property => {
            propertyIds.push(property.property.propertyId)
        })

        history.propertyIds = propertyIds        

        //Api.setHistory(history)
        
        Store.commit({
            type: 'setHistory',
            value: history,
        })
    },

    addToHistory: function(payload)
    {
        let history = Store.state.history,
            visitedProperties = history.properties,
            isVisited = visitedProperties.filter(function(property) {
                return property.property.propertyId == payload.value.propertyId
            }).length

        if(isVisited !== 0) return

        history.properties.unshift({
            type: payload.type,
            property: payload.value,
            checkin: Store.state.dates.checkin,
            checkout: Store.state.dates.checkout,
            occupancy: Store.state.dates.occupancy,
            lat: Store.state.geo.latitude,
            lng: Store.state.geo.longitude,
            offset: 0,
            matchLevel: Store.state.geo.matchLevel,
            label: Store.state.geo.label,
            searchCounter: 0,
            ignoreMapRedirect: true, // https://trello.com/c/7oWYmzkB/855-invadobay-slider-monografico
        })

        history.properties = history.properties.slice(0, MAX_FAVORITES)

        this.setHistory(history)
    },

    checkIsVisited(propertyId)
    {
        if(Store.state.history.properties == undefined) return false
        
        return Store.state.history.propertyIds.includes(propertyId)

        /*
        let findVisited,
            _this = this

        if(this.history.properties == undefined)
        {
            this.visited = false
            return
        }

        findVisited = this.history.properties.filter(function(property){
            return property.property.propertyId == _this.property.propertyId
        }).length
        
        this.visited = (findVisited !== 0)
        */
    }, 

    goToHistoryItem: function()
    {
        /*
      this.mixinSendMutation('setGeo', {
        latitude: this.inSalabam.infoPad.geo.latitude,
        longitude: this.inSalabam.infoPad.geo.longitude,
        label: this.inSalabam.infoPad.geo.label,
        matchLevel: this.inSalabam.infoPad.geo.matchLevel,
        ignoreMapRedirect: true, // https://trello.com/c/7oWYmzkB/855-invadobay-slider-monografico
      })
      // rendo coerenti i dati nello store per eventuali ricerche successive
      this.mixinSendMutation('setTriggerSearch', true)
      this.mixinSendMutation('setCheckin', this.inSalabam.infoPad.search.checkin)
      this.mixinSendMutation('setCheckout', this.inSalabam.infoPad.search.checkout)
      this.mixinSendMutation('setAdults', this.inSalabam.infoPad.search.occupancy.adults)
      this.mixinSendMutation('setChildren', this.inSalabam.infoPad.search.occupancy.children)
      this.mixinSendMutation('setInSalabamId', this.inSalabam.infoPad.inSalabamId)

      this.mixinSendMutation('setPropertyDetail', property)

      this.mixinGoTo('property',{
        propertyId: property.property_id,
        propertyPad: property,
        propertyName: property.name,
        newWindow: false
      })        
        */
    },

    getLiteProperty(property, from)
    {
        this.debug('parsing property from: ' + from)

        let name,
            address,
            city,
            hotelRating,
            shortDescription,
            latitude,
            longitude,
            image,
            scoreDescription,
            salabamScore,
            propertyAmenities,
            propertyId,
            livePrice,
            amenities

        switch (from) {
            case 'detail':
                    name = property.name
                    address = property.address.line_1
                    city = property.address.city
                    hotelRating = property.static.hotelRating
                    shortDescription = property.details.location
                    latitude = property.location.coordinates.latitude
                    longitude = property.location.coordinates.longitude
                    //image = property.images.filter(function(image){ return image.hero_image})[0].links['1000px'].href
                    image = property.images.filter(function(image){ return image.hero_image})[0].src
                    scoreDescription = property.static.score_description
                    salabamScore = parseInt(property.static.salabamScore)
                    propertyAmenities = {}
                    propertyId = parseInt(property.property_id)
                    livePrice = 0
                    amenities = {}       
                                        
                break;
            
                case 'preview':
                    name = property.name
                    address = property.address1
                    city = property.city
                    hotelRating = property.hotelRating
                    shortDescription = property.shortDescription
                    latitude = parseFloat(property.latitude)
                    longitude = parseFloat(property.longitude)
                    image = property.thumbNailUrl
                    scoreDescription = property.score_description
                    salabamScore = parseInt(property.salabamScore)
                    propertyAmenities = {}
                    propertyId = parseInt(property.property_id)
                    livePrice = 0
                    amenities = {}             
                break;

            
            case 'suggestion':
                    name = property.name
                    address = property.address1
                    city = property.city
                    hotelRating = property.hotelRating
                    shortDescription = property.shortDescription
                    latitude = parseFloat(property.latitude)
                    longitude = parseFloat(property.longitude)
                    image = property.thumbNailUrl
                    scoreDescription = property.score_description
                    salabamScore = parseInt(property.salabamScore)
                    propertyAmenities = {}
                    propertyId = parseInt(property.property_id)
                    livePrice = 0
                    amenities = {}             
                break;

            default:
                break;
        }

        return {
            name: name,
            address: address,
            city: city,
            hotelRating: hotelRating,
            shortDescription: shortDescription,
            latitude: latitude,
            longitude: longitude,
            image: image,
            scoreDescription: scoreDescription,
            salabamScore: salabamScore,
            propertyAmenities: propertyAmenities,
            propertyId: propertyId,
            livePrice: livePrice,
            amenities: amenities,
        }
    },

    goToProperty(property, matchlevel)
    {
        let property_id = (property.property.propertyId).toString()
        
        property.property_id = property_id

        //User.goToProperty(property, 'favorites')
        Utilities.methods.mixinSendMutation('setGeo', {
            latitude: property.lat,
            longitude: property.lng,
            label: property.property.name,
            matchLevel: matchlevel,
            properties_ids: [property_id],
            ignoreMapRedirect: true, // https://trello.com/c/7oWYmzkB/855-invadobay-slider-monografico
        })

        // rendo coerenti i dati nello store per eventuali ricerche successive
        //this.mixinSendMutation('setTriggerSearch', true)
        //this.mixinSendMutation('setCheckin', property.checkin)
        //this.mixinSendMutation('setCheckout', property.checkout)
        //this.mixinSendMutation('setAdults', this.inSalabam.infoPad.search.occupancy.adults)
        //this.mixinSendMutation('setChildren', this.inSalabam.infoPad.search.occupancy.children)
        //this.mixinSendMutation('setInSalabamId', this.inSalabam.infoPad.inSalabamId)

        Utilities.methods.mixinSendMutation('setPropertyDetail', property)

        Utilities.methods.mixinGoTo('property',{
            propertyId: property_id,
            propertyPad: property,
            propertyName: property.property.name,
            newWindow: false
        })
    },

    searchLite(searchParameters, matchlevel)
    {
        Utilities.methods.mixinSendMutation('setGeo', {
            latitude: searchParameters.latitude,
            longitude: searchParameters.longitude,
            label: searchParameters.label, // history|favorites
            matchLevel: matchlevel,
            properties_ids: searchParameters.properties_ids,
        })

        Utilities.methods.mixinSendMutation('setLitePropertyIds', searchParameters.properties_ids)
        Utilities.methods.mixinSendMutation('setTriggerSearch', true)
        //Utilities.methods.mixinSendMutation('setCheckin', this.inSalabam.infoPad.search.checkin)
        //Utilities.methods.mixinSendMutation('setCheckout', this.inSalabam.infoPad.search.checkout)
        Utilities.methods.mixinSendMutation('setAdults', searchParameters.adults)
        Utilities.methods.mixinSendMutation('setChildren', searchParameters.children)
        //Utilities.methods.mixinSendMutation('setInSalabamId', this.inSalabam.infoPad.inSalabamId)

        Utilities.methods.mixinGoTo('search',{})
    }
}