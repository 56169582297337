import Vue from 'vue';
import Vuex from 'vuex';
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex);
Vue.use(VueLocalStorage)

let state,
    localStorageState,
    hashState

// default empty state
state = {
    integration:
    {
        availability: 0
    },
    occupancy:{
        adults: 0,
        children: []
    },
    dates: {
        checkin: '',//formato anno-mese-giorno es '2020-10-01'
        checkout: '',
        nights: 0,
    },
    geo: {
        latitude:0,
        longitude:0,
        matchLevel:'',
        label:'',
        inSalabamId: 0,
    },
    map:{
        geography: {},
        data: {}
    },
    amenities: [],
    categories: [],
    rates: [],
    triggerSearch: false,
    token: '',
    searchCounter: 0,
    offsetCounter: 0,
    searchLogId: 0,
    bookingData: {},
    affiliateReferenceId: '',
    welfareAvailability: false,
    scroll:0,
    sortResults:
    {
        by: 'defaultSorting',
        order: 'ASC',
        label: 'Default'
    },
    propertyDetail: {},
    discountCodes: [],
    history:{//cronologia
        properties: [],
    },
    favorites:{//preferiti
        properties: [],
    },
    litePropertyIds: [],
}

// state from browser local storage
localStorageState = Vue.localStorage.get(process.env.VUE_APP_STORE_KEY)
if(localStorageState)
{
    let newState = JSON.parse(localStorageState)

    //window.console.log(newState)

    if(newState && newState.geo && newState.geo.label == 'punto selezionato') newState.geo = null // ignoro la ricerca salvata se è un click su mappa senza geocoding inverso

    Object.keys(newState).forEach(e => 
        {
            if(newState[e] && newState[e] != null)
            {
                state[e] = newState[e]
            }
        }
    )
    state.triggerSearch = false
    state.offsetCounter = 0
    state.searchLogId = 0
    state.welfareAvailability = false
    state.scroll = 0
    state.sortResults =
    {
        by: 'defaultSorting',
        order: 'ASC',
        label: 'Default'
    }
    //state.searchCounter = 0
    //state.token = ''
}

// state from MD5 query string
hashState = (window.location.hash).match('[?&]' + 'state' + '=([^&]+)')
if(hashState)
{
    let newState = JSON.parse(window.atob(hashState[1]))
    Object.keys(newState).forEach(e => 
        {
            if(newState[e])
            {
                state[e] = newState[e]
            }
        }
    )
}

export default new Vuex.Store({
    //strict: process.env.VUE_APP_STORE_STRICT === 'true',
    //strict: false,
    state: state,
    getters: {
        getAvailability(state)
        {
            return state.integration.availability
        },
        getOccupancy(state)
        {
            return state.occupancy
        },
        getAdults(state)
        {
            return state.occupancy.adults
        },
        getChildren(state)
        {
            return state.occupancy.children
        },
        getDates(state)
        {
            return state.dates
        },
        getCheckin(state)
        {
            if(!state.dates.checkin || state.dates.checkin == 'Invalid Date') return ''

            let checkin = new Date(state.dates.checkin),
                mincheckin = new Date()

                // il -1 serve in quanto qui stiamo già gestendo la data minima di checkin possible
                mincheckin.setDate(mincheckin.getDate() + Vue.prototype.$config.guiSettings.minDays - 1)

                if(mincheckin.getHours()>13)
                {
                  mincheckin.setDate(mincheckin.getDate() + 1)
                }

            if(checkin < mincheckin)
            {
                // se il checkin salvato è passato, setto le date del calendario a una settimana in avanti
                let newCheckin = new Date()
                newCheckin.setDate(newCheckin.getDate() + 7)
                let month = newCheckin.getMonth()+1
                let day = newCheckin.getDate()
                month = (month.toString().length < 2) ? '0'+ month : month
                day = (day.toString().length < 2) ? '0'+ day : day
                state.dates.checkin = newCheckin.getFullYear()+'-'+ month +'-'+ day
            }

            /* CORONAVIRUS */
                checkin = new Date(state.dates.checkin)
                let coronaMinCheckin = new Date(Vue.prototype.$config.guiSettings.minCheckin);
                if(coronaMinCheckin > checkin)
                {
                    let newCheckin = coronaMinCheckin
                    let month = newCheckin.getMonth()+1
                    let day = newCheckin.getDate()

                    month = (month.toString().length < 2) ? '0'+ month : month
                    day = (day.toString().length < 2) ? '0'+ day : day

                    state.dates.checkin = newCheckin.getFullYear()+'-'+ month +'-'+ day
                }
            /* coronavirus */
            return state.dates.checkin
        },
        getCheckout(state)
        {
            if(!state.dates.checkout || state.dates.checkout == 'Invalid Date') return ''

            let checkout = new Date(state.dates.checkout),
                checkin = new Date(state.dates.checkin)

            if(checkin >= checkout)
            {
                let newCheckout = new Date(checkin)
                newCheckout.setDate(newCheckout.getDate() + 1)
                let month = newCheckout.getMonth()+1
                let day = newCheckout.getDate()
                month = (month.toString().length < 2) ? '0'+ month : month
                day = (day.toString().length < 2) ? '0'+ day : day
                state.dates.checkout = newCheckout.getFullYear()+'-'+ month +'-'+ day
            }
          return state.dates.checkout
        },
        getNights(state)
        {
            return state.dates.nights
        },
        getGeo(state)
        {
            return state.geo
        },
        getLatitude(state)
        {
            return state.geo.latitude
        },
        getLongitude(state)
        {
            return state.geo.longitude
        },
        getMatchLevel(state)
        {
            return state.geo.matchLevel
        },
        getLabel(state)
        {
            return state.geo.label
        },
        getAmenities(state)
        {
            return state.amenities
        },
        getCategories(state)
        {
            return state.categories
        },
        getRates(state)
        {
            return state.rates
        },
        getTriggerSearch(state)
        {
            return state.triggerSearch
        },
        getToken(state)
        {
            return state.token
        },
        getSearchCounter(state)
        {
            return state.searchCounter
        },
        getOffsetCounter(state)
        {
            return state.offsetCounter
        },
        getSearchLogId(state)
        {
            return state.searchLogId
        },
        getInSalabamId(state)
        {
            return state.geo.inSalabamId
        },
        getMap(state)
        {
            return state.map
        },
        getBookingData(state)
        {
            return state.bookingData
        },
        getAffiliateReferenceId(state)
        {
            return state.affiliateReferenceId
        },
        getWelfareAvailabilty(state)
        {
            return state.welfareAvailability
        },
        getlastPublicHomeModalViewTs(state){
          return state.lastPublicHomeModalViewTs
        },
        getScroll(state)
        {
            return state.scroll
        },
        getSortResults(state)
        {
            return state.sortResults
        },
        getPropertyDetail(state)
        {
            return state.propertyDetail
        },
        getDiscountCodes(state)
        {
            return state.discountCodes
        },
        getCacheKeys(state)
        {
          return state.cacheKeys
        },
        getHistory(state)
        {
          return state.history
        },
        getFavorites(state)
        {
          return state.favorites
        },
        getLitePropertyIds(state)
        {
          return state.litePropertyIds
        },
    },
    mutations: {
        setIntegration(state,payload)
        {
          state.integration = payload.value
        },
        setAvailability(state,payload)
        {
            state.integration.availability = payload.value
        },
        setOccupancy(state,payload)
        {
            state.occupancy = payload.value
        },
        setAdults(state,payload)
        {
            state.occupancy.adults = payload.value
        },
        setChildren(state,payload)
        {
            state.occupancy.children = payload.value
        },
        setDates(state,payload)
        {
            state.dates = payload.value
        },
        setCheckin(state,payload)
        {
            state.dates.checkin = payload.value
        },
        setCheckout(state,payload)
        {
            state.dates.checkout = payload.value
        },
        setNights(state,payload)
        {
            state.dates.nights = payload.value
        },
        setGeo(state,payload)
        {
            state.geo = payload.value
        },
        setLatitude(state,payload)
        {
            state.geo.latutude = payload.value
        },
        setLongitude(state,payload)
        {
            state.geo.longitude = payload.value
        },
        setMatchLevel(state,payload)
        {
            state.geo.matchLevel = payload.value
        },
        setLabel(state,payload)
        {
            state.geo.label = payload.value
        },
        setAmenities(state,payload)
        {
            state.amenities = payload.value
        },
        setCategories(state,payload)
        {
            state.categories = payload.value
        },
        setRates(state,payload)
        {
            state.rates = payload.value
        },
        setTriggerSearch(state,payload)
        {
            state.triggerSearch = payload.value
        },
        setToken(state,payload)
        {
            state.token = payload.value
        },
        setSearchCounter(state,payload)
        {
            state.searchCounter = payload.value
        },
        setOffsetCounter(state,payload)
        {
            state.offsetCounter = payload.value
        },
        setSearchLogId(state,payload)
        {
            state.searchLogId = payload.value
        },
        setInSalabamId(state,payload)
        {
            state.geo.inSalabamId = payload.value
        },
        setMap(state,payload)
        {
            state.map = payload.value
        },
        setBookingData(state,payload)
        {
            state.bookingData = payload.value
        },
        setAffiliateReferenceId(state,payload)
        {
            state.affiliateReferenceId = payload.value
        },
        setWelfareAvailability(state,payload)
        {
            state.welfareAvailability = payload.value
        },
        setlastPublicHomeModalViewTs(state, payload)
        {
          state.lastPublicHomeModalViewTs = payload.value
        },
        setScroll(state, payload)
        {
          state.scroll = payload.value
        },
        setSortResults(state, payload)
        {
          state.sortResults = payload.value
        },
        setPropertyDetail(state, payload)
        {
          state.propertyDetail = payload.value
        },
        setDiscountCodes(state, payload)
        {
          state.discountCodes = payload.value
        },
        setHistory(state, payload)
        {
          if(payload.value.properties == undefined)
          {
            state.history = {
                properties: [],
            }        
          }  
          else
          {
              state.history = payload.value
          }
        },
        setFavorites(state, payload)
        {
          if(payload.value.properties == undefined)
          {
            state.favorites = {
                properties: [],
            }        
          }  
          else
          {
              state.favorites = payload.value
          }
        },
        setLitePropertyIds(state, payload)
        {
          state.litePropertyIds = payload.value
        },
        setCacheKeys(state,payload)
        {
          // chiavi cache usate, le inoltrerò al checkout così da poter spianare la cache in automatico in caso di probemi
          state.cacheKeys = payload.value
        },
    },
    actions: {
        /*
        toggle(context, payload) {
            context.commit('toggle', payload);
        }
        */
    }
});
