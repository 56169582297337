import Api from './api'
import Vue from 'vue'
import Store from "./vuex/store";
//import Store from './vuex/store'

export default {
    created(){

    },
    data(){
        return {
            //configData: {}
        }
    },
    methods: {
        appConfig() {

            let _this = this

            /*
                startup applicazione: senza il $config non mostro niente => ritorno una promise
                questa condizione si verifica SOLO dentro App.vue prima che il componente App venga montato:
                se viene chiamato il mixin appConfig() in un punto diverso da App.vue, significa che l'app è
                già stata istanziata, quindi che Vue.prototype.$config è già stato popolato: in tutti questi casi
                (al momento solo dentro customize.vue) viene restituito un OGGETTO e non una promise,
                perché non ha senso che il comportamento resti asincrono come è (correttamente) in App.vue
            */

            if(Object.keys(Vue.prototype.$config).length == 0)
            {
                let promise =Api.getConfig()

                promise.then((value) => {

                    Vue.prototype.$config = value

                    if(value.integration)
                    {
                      

                        // compatibilità pre flag welfare
                        value.integration.welfare = (value.integration.welfare == undefined)? true : value.integration.welfare

                        this.mixinSendMutation('setIntegration', value.integration)

                        if(value.integration.loginDiscountCode){
                          this.mixinSendMutation('setDiscountCodes',[value.integration.loginDiscountCode])
                        }else{
                          this.mixinSendMutation('setDiscountCodes',[])
                        }

                        // pilota se iniziare con il pulsante "mostra in base al credito" attivo
                        if(value.integration.startFilteringByAvailability) this.mixinSendMutation('setWelfareAvailability', true)

                        setTimeout(function(){

                          if(window.Tawk_API) {

                            let tawkAttributes = {
                              'name': (value.integration.displayName) ? value.integration.displayName : '',
                              'email': (value.integration.email) ? value.integration.email : '',
                              'token': (value.token) ? value.token : '',
                              'portale': (value.integration.customer) ? value.integration.customer : '',
                              'azienda': (value.integration.company) ? value.integration.company : '',
                              'credito': (value.integration.availability) ? value.integration.availability : '',
                            }

                            if(window.Tawk_API.setAttributes !== undefined){

                              window.Tawk_API.setAttributes(tawkAttributes, function (error) {

                                if (error) {
                                  window.console.log('tawk setAttributes error')
                                  window.console.log(error)
                                }

                              })

                            }else{

                              window.Tawk_API.onLoad = function(){
                                window.Tawk_API.setAttributes(tawkAttributes, function (error) {

                                  if (error) {
                                    window.console.log('tawk onLoad setAttributes error')
                                    window.console.log(error)
                                  }

                                })
                              }

                            }

                          }

                          if(window.LogRocket) {
                            window.LogRocket.identify(value.integration.email, {
                              name: (value.integration.displayName)? value.integration.displayName : '',
                              email: (value.integration.email)? value.integration.email : '',
                              // custom variables
                              customer: (value.integration.customer)? value.integration.customer : '',
                              company: (value.integration.company)? value.integration.company : '',
                              token: (value.token)? value.token : '',
                            });

                            window.LogRocket.track('sso')
                          }


                          if(window.clarity){
                            window.clarity("set", "token", value.token)
                            window.clarity("set", "customer", (value.integration.customer)? value.integration.customer : '')
                            window.clarity("set", "company", (value.integration.company)? value.integration.company : '')
                            window.clarity("set", "sso", (value.integration.email)? value.integration.email : '')
                          }


                          if(_this.$gtm) _this.$gtm.trackEvent({
                            event: 'customEvent',  // Event type [default = 'interaction'] (Optional) tag manager tracks 'customEvent'
                            category: 'userBehaviour',
                            action: 'sso',
                            label: Vue.prototype.$config.integration.email,
                            value: (Vue.prototype.$config.integration.availability >= 0)? Vue.prototype.$config.integration.availability : 0,
                            //noninteraction: false // Optional
                          });

                          if(_this.$gtm) _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'sso',
                            label: Vue.prototype.$config.integration.customerDisplayName,
                            value: (Vue.prototype.$config.integration.availability >= 0)? Vue.prototype.$config.integration.availability : 0,
                          });

                          if(_this.$gtm) _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'welfare',
                            label: (Vue.prototype.$config.integration.welfare)? 'welfare' : '',
                            value: (Vue.prototype.$config.integration.welfare)? 1 : 0,
                          });

                          if(_this.$gtm && Vue.prototype.$config.integration.customizationUse) _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'partnerBehaviour',
                            action: 'customizationUse',
                            label: Vue.prototype.$config.integration.customizationUse,
                          });

                          if(_this.$gtm) _this.$gtm.trackEvent({
                            event: 'customEvent',
                            category: 'companyBehaviour',
                            action: 'sso',
                            label: Vue.prototype.$config.integration.company,
                            value: (Vue.prototype.$config.integration.availability >= 0)? Vue.prototype.$config.integration.availability : 0,
                          });

                          if(window.fbq) window.fbq('trackCustom', 'Sso', {
                            customer: (value.integration.customer)? value.integration.customer : '',
                            company: (value.integration.company)? value.integration.company : '',
                            availability: (Vue.prototype.$config.integration.availability >= 0)? Vue.prototype.$config.integration.availability : 0,
                          });

                        }, 2000);


                    }else{

                      Store.replaceState({}) // se non c'è integration spiano lo state, altrimenti restava integration.availability in giro che faceva tornare true mixin isAuthenticated

                      setTimeout(function(){
                        if(window.fbq) window.fbq('trackCustom', 'PublicAccess', {});
                      }, 2000);

                    }

                    if(value.affiliateReferenceId)
                    {
                      this.mixinSendMutation('setAffiliateReferenceId',value.affiliateReferenceId)
                    }

                    this.mixinSendMutation('setToken',value.token)



                    if(
                         this.$store
                      && this.$store.state
                      && this.$store.state.geo
                      && this.$store.state.geo.inSalabamId != undefined
                      && this.$store.state.geo.inSalabamId != 0
                    ){

                      // al load dell'applicazione cancello l'eventuale ricerca salvata (se è su un inSalabam)
                      // altrimenti facendo sso nei vari prodotti verticali, l'autocomplete potrebbe mostrare
                      // la ricerca del prodotto precedente
                      if(process.env.VUE_APP_MODE != 'production') window.console.log('cancello ricerca salvata')

                      this.mixinSendMutation('setGeo', {
                        latitude: '',
                        longitude: '',
                        label: '',
                        matchLevel: '',
                        ignoreMapRedirect: false,
                      })

                      this.mixinSendMutation('setInSalabamId', 0)

                    } // if this.$store.state.geo.inSalabamId != 0

                    if(value.searchSettings && value.searchSettings.defaultSorting){
                      this.mixinSendMutation('setSortResults', value.searchSettings.defaultSorting)
                    }

                    
                })
                
                return promise
            }
            /*
            return new Promise((resolve, reject) => {
                resolve(Vue.prototype.$config);
                reject('Could not load app config from config.js');
            });
            */

            // navigaxion
            return Vue.prototype.$config
        }
    }
}