import Vue from 'vue'
import Router from 'vue-router'
import Utilities from './utilities'

const Notifications = () => import('./components/views/notifications')
  .then(function(Notifications) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Notifications View loaded')
    return Notifications
  })

const Home = () => import(/* webpackChunkName: "home-chunk" */'./components/views/home')
  .then(function(Home) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Home View loaded')
    return Home
  })

const NotFound = () => import(/* webpackChunkName: "home-chunk" */'./components/views/not-found')
  .then(function(Home) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Home View loaded')
    return Home
  })

const SistemiDiPagamento = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/sistemi-di-pagamento')
  .then(function(SistemiDiPagamento) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SistemiDiPagamento View loaded')
    return SistemiDiPagamento
  })

const LandingAon = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-aon')
  .then(function(LandingAon) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: LandingAon View loaded')
    return LandingAon
  })

const VivamodBusiness = () => import(/* webpackChunkName: "business-chunk" */'./components/views/public-pages/vivamod-business')
  .then(function(VivamodBusiness) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: VivamodBusiness View loaded')
    return VivamodBusiness
  })

const HomeInVadobay= () => import(/* webpackChunkName: "home-chunk" */'./components/views/home-in-vadobay')
  .then(function(HomeInVadobay) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: HomeInVadobay View loaded')
    return HomeInVadobay
  })

const Post = () => import(/* webpackChunkName: "post-chunk" */'./components/views/blog/post')
  .then(function(Post) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Post View loaded')
    return Post
  })

const Search = () => import(/* webpackChunkName: "search-chunk" */'./components/views/search')
  .then(function(Search) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Search View loaded')
    return Search
  })

const GoogleMap = () => import(/* webpackChunkName: "map-chunk" */'./components/views/search-google-map')
  .then(function(GoogleMap) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: GoogleMap View loaded')
    return GoogleMap
  })

const Property = () => import(/* webpackChunkName: "property-chunk" */'./components/views/search-property')
  .then(function(Property) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Property View loaded')
    return Property
  })

const Checkout = () => import(/* webpackChunkName: "purchase-chunk" */'./components/views/checkout')
  .then(function(Checkout) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Checkout View loaded')
    return Checkout
  })

const Confirm = () => import(/* webpackChunkName: "purchase-chunk" */'./components/views/confirm')
  .then(function(Confirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Confirm View loaded')
    return Confirm
  })

const Page = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/page')
  .then(function(Page) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Page View loaded')
    return Page
  })

const Covid19 = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/covid19.vue')
  .then(function(Covid19) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Covid19 View loaded')
    return Covid19
  })

const Faq = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/faq')
  .then(function(Faq) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Faq View loaded')
    return Faq
  })

const Terms = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/terms')
  .then(function(Terms) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Terms View loaded')
    return Terms
  })

const Informazioni = () => import(/* webpackChunkName: "publics-chunk" */'./components/' + process.env.VUE_APP_CUSTOM_SRC + 'views/public-pages/informazioni')
  .then(function(Informazioni) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Informazioni View loaded')
    return Informazioni
  })

  const Adv = () => import(/* webpackChunkName: "publics-chunk" */'./components/' + process.env.VUE_APP_CUSTOM_SRC + 'views/public-pages/adv')
  .then(function(Adv) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Informazioni View loaded')
    return Adv
  })

const TicketCompliments = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/ticket-compliments')
  .then(function(TicketCompliments) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: TicketCompliments View loaded')
    return TicketCompliments
  })

const DcSso = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/dc-sso')
  .then(function(DcSso) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: DcSso View loaded')
    return DcSso
  })

const PaymentsLink = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/payments-link')
  .then(function(PaymentsLink) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Payments Link View loaded')
    return PaymentsLink
  })

const CustomLandingDc = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/custom-landing-dc')
  .then(function(CustomLandingDc) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: CustomLandingDc View loaded')
    return CustomLandingDc
  })

  const JpSso = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/jp-sso')
  .then(function(JpSso) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: JpSso View loaded')
    return JpSso
  })

const JpCheckout = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/jp-checkout')
  .then(function(JpCheckout) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: JpCheckout View loaded')
    return JpCheckout
  })

const Error = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/error')
  .then(function(Error) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Error View loaded')
    return Error
  })

const Info = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/info')
  .then(function(Info) {
    window.console.log('async: Info View loaded')
    return Info
  })

const Maintenance = () => import(/* webpackChunkName: "maintenance-chunk" */'./components/views/public-pages/maintenance')
  .then(function(Maintenance) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Maintenance View loaded')
    return Maintenance
  })

const ScalapayConfirm = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/scalapay-confirm')
  .then(function(ScalapayConfirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ScalapayConfirm View loaded')
    return ScalapayConfirm
  })

const ScalapayCancel = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/scalapay-cancel')
  .then(function(ScalapayCancel) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ScalapayCancel View loaded')
    return ScalapayCancel
  })

const SatispayConfirm = () => import(/* webpackChunkName: "publics-chunk" */'./components/views/public-pages/satispay-confirm')
  .then(function(SatispayConfirm) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SatispayConfirm View loaded')
    return SatispayConfirm
  })

const BlogHomeCategory = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/blog/blog-home-category')
  .then(function(BlogHomeCategory) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: BlogHomeCategory View loaded')
    return BlogHomeCategory
  })

const LandingScalapay = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/landing-scalapay')
  .then(function(LandingScalapay) {
    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: LandingScalapay View loaded')
    return LandingScalapay
  })

const DoubleCheck = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/doublecheck/doublecheck')
  .then(function(DoubleCheck) {
    window.console.log('async: DoubleCheck View loaded')
    return DoubleCheck
  })

const PinkPalaceLanding = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/public-pages/pink-palace-landing')
  .then(function(PinkPalaceLanding) {
    window.console.log('async: PinkPalaceLanding View loaded')
    return PinkPalaceLanding
  })

const User = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/user')
  .then(function(User) {
    window.console.log('async: User View loaded')
    return User
  })

const TestCustomizations = () => import(/* webpackChunkName: "publics-chunk" */'@/components/views/debug/customizations')
  .then(function(TestCustomizations) {
    window.console.log('async: TestCustomizations View loaded')
    return TestCustomizations
  })

Vue.use(Router)

let router = new Router({
    mode: process.env.VUE_APP_ROUTER_MODE,
    
    scrollBehavior: function(_, _2, savedPosition) {
      if(savedPosition) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ savedPosition })
          }, 100)
        })
      }
      return {selector: '#app'}
    },
    
    routes: [

      // REDIRECTS
      { path: '/condizioni-servizio', redirect: { name: 'terms' }},

      // PUBLIC
      {
          path: '/',
          alias: ['/index.html'],
          name: 'homepage',
          component: Home,
          publicRoute: true, //custom attribute
      },
      // DEBUG
      {
        path: '/test-customizations',
        name: 'test-customizations',
        component: TestCustomizations,
        publicRoute: true, //custom attribute
      },      
      {
        path: '/in-vadobay/:inSalabamId/:inSalabamTitle',
        name: 'in-vadobay',
        component: HomeInVadobay,
        publicRoute: true, //custom attribute
        props: route => ({
          inSalabamId: route.params.inSalabamId,
          inSalabamTitle: route.params.inSalabamTitle,
          setGeo: (route.params.setGeo != undefined)? route.params.setGeo : true,
        }),
      },
      {
        path: '/aon',
        name: 'aon',
        component: LandingAon,
        publicRoute: true, //custom attribute
      },
      {
        path: '/buoni-sconto-e-ticket',
        name: 'sistemi-di-pagamento',
        component: SistemiDiPagamento,
        publicRoute: true, //custom attribute
      },
      {
          path: '/business',
          name: 'vivamod-business',
          component: VivamodBusiness,
          publicRoute: true, //custom attribute
      },
      {
        path: '/in-vivamod/:inSalabamId/:inSalabamTitle',
        name: 'in-vivamod',
        component: HomeInVadobay,
        publicRoute: true, //custom attribute
        props: route => ({
          inSalabamId: route.params.inSalabamId,
          inSalabamTitle: route.params.inSalabamTitle,
          setGeo: (route.params.setGeo != undefined)? route.params.setGeo : true,
        }),
      },
      {
          path: '/error',
          name: 'error',
          component: Error,
          publicRoute: true, //custom attribute
        },
        {
          path: '/info',
          name: 'info',
          component: Info,
          publicRoute: true, //custom attribute
        },
        {
          path: '/ticket-compliments',
          name: 'ticket-compliments',
          component: TicketCompliments,
          publicRoute: true, //custom attribute
        },
        {
          path: '/adv',
          name: 'adv',
          component: Adv,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'informazioni',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'contacts',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
            path: '/informazioni',
            name: 'how-it-works',
            component: Informazioni,
            publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'how-etc-works',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'about',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'customer-care',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'business',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/informazioni',
          name: 'perche-scegliere-vivamod',
          component: Informazioni,
          publicRoute: true, //custom attribute
        },
        {
          path: '/faq',
          name: 'faq',
          component: Faq,
          publicRoute: true, //custom attribute
        },
        {
          path: '/termini-e-condizioni',
          name: 'terms',
          component: Terms,
          publicRoute: true, //custom attribute
        },
        {
          path: '/privacy-policy',
          name: 'privacy-policy',
          beforeEnter() {location.href = 'https://www.iubenda.com/privacy-policy/32772449'},
          publicRoute: true, //custom attribute
        },
        {
          path: '/page',
          name: 'page',
          component: Page,
          publicRoute: true, //custom attribute
        },
        {
          path: '/confirm',
          name: 'confirm',
          component: Confirm,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
          //http://localhost:8080/#/prenotazioni/LB3CL2U/7927002216768
          path: '/prenotazioni/:confirmcode/:itinerary_id',
          name: 'reservations',
          component: Confirm,
          publicRoute: true, //custom attribute
          props: (route) => {
            return {
              confirmcode: route.params.confirmcode,
              itinerary_id: route.params.itinerary_id,
              prerenderStatusCode: '404',
              ignoreRedirect: true,
            }
          },
        },
        {
          path: '/covid-19',
          name: 'covid19',
          component: Covid19,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
            path: '/blog',
            name: 'blog',
            component: BlogHomeCategory,
            publicRoute: true, //custom attribute
            props: () => {
                return {
                categorySlug: 'consumer'
                }
            }
        },        
        {
            path: '/scalapay',
            name: 'scalapay',
            component: LandingScalapay,
            publicRoute: true, //custom attribute
        },
        {
          name: 'post',
          path: '/post/:postId/:title',
          component: Post,
          props: (route) => {
            const postId = Number.parseInt(route.params.postId, 10)
            if (Number.isNaN(postId)) {
              return 0
            }
            return { postId }
          },
          publicRoute: true, //custom attribute
        },
        {
          path: '/ho-un-buono',
          name: 'dc-sso',
          component: DcSso,
          publicRoute: true, //custom attribute
        },
        {
          path: '/ho-un-buono/:discountCodeUrl', // trailing slash obbligatorio se ci sono . nelle props dell'url
          name: 'dc-sso-fields',
          component: DcSso,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
          path: '/ho-un-buono/:discountCodeUrl/:nameUrl/:surnameUrl/:emailUrl', // trailing slash obbligatorio se ci sono . nelle props dell'url
          name: 'dc-sso-fields',
          component: DcSso,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
          path: '/paga/:pay_code/:payment_link_id',
          name: 'payments-link',
          component: PaymentsLink,
          props: true,
          publicRoute: true,
        },
        {
          path: '/landing/:customDesignCode', // trailing slash obbligatorio se ci sono . nelle props dell'url
          name: 'custom-landing-dc',
          component: CustomLandingDc,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
          path: '/landing/:customDesignCode/:discountCodeUrl', // trailing slash obbligatorio se ci sono . nelle props dell'url
          name: 'custom-landing-dc',
          component: CustomLandingDc,
          props: true,
          publicRoute: true, //custom attribute
        },
        {
          // per gestire le vecchie urls indicizzate senza post id
          // tornando 0, l'api risponderà che il post non è più disponibile
          // forzando un robots noindex
          name: 'oldPost',
          path: '/post/:title',
          component: Post,
          //props: (route) => {
          props: () => {
            return 0
          },
          publicRoute: true, //custom attribute
        },
        {
          path: '/jp-sso',
          name: 'jp-sso',
          component: JpSso,
          publicRoute: true, //custom attribute
        },
        {
          path: '/jp-checkout',
          name: 'jp-checkout',
          component: JpCheckout,
          publicRoute: true, //custom attribute
        },
        // PRIVATE (token)
        {
            path: '/checkout',
            name: 'checkout',
            component: Checkout,
            publicRoute: false, //custom attribute
        },
        {
          path: '/checkout-scalapay',
          name: 'checkout-scalapay',
          component: Checkout,
          publicRoute: false, //custom attribute
          props: {useSidebarScalapay: true}
        },
        {
          path: '/checkout-satispay',
          name: 'checkout-satispay',
          component: Checkout,
          publicRoute: false, //custom attribute
          props: {useSidebarSatispay: true}
        },
        {
          path: '/satispay-confirm/:integratedOrderId/:cancellaraToken',
          name: 'satispay-confirm',
          component: SatispayConfirm,
          publicRoute: true, //custom attribute
          props: route => ({
            integratedOrderId: route.params.integratedOrderId,
            cancellaraToken: route.params.cancellaraToken,
          }),
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: Notifications,
            publicRoute: false, //custom attribute
        },
        {
            path: '/maintenance',
            name: 'maintenance',
            component: Maintenance,
            publicRoute: false, //custom attribute
        },     
        {
            path: '/ricerca',
            name: 'search',
            component: Search,
            publicRoute: false, //custom attribute
            children: [
                {
                    name: 'property',
                    path: '/ricerca/property-:propertyId',
                    component: Property,
                    props: true,
                },
                {
                    name: 'map-list',
                    //path: '/ricerca/map-list-:slug',
                    path: '/ricerca/map-list',
                    component: GoogleMap,
                    props: true,
                },
                {
                    name: 'map-detail',
                    //path: '/ricerca/map-property-:slug',
                    path: '/ricerca/map-property',
                    //component: GoogleMapDetail,
                    component: GoogleMap,
                    props: true,
                }
            ],
        },
        {
          path: '/scalapay-confirm',
          name: 'scalapay-confirm',
          component: ScalapayConfirm,
          publicRoute: true, //custom attribute
        },
        {
          path: '/scalapay-cancel',
          name: 'scalapay-cancel',
          component: ScalapayCancel,
          publicRoute: true, //custom attribute
        },
        {
            name: 'doublecheck',
            path: '/doublecheck/:voucher_id/:itinerary_id',
            component: DoubleCheck,
            props: true,
            publicRoute: true, //custom attribute
        },
        {
            name: 'pink-palace',
            path: '/pink-palace',
            component: PinkPalaceLanding,
            props: true,
            publicRoute: true, //custom attribute
        },
        {
            name: 'user',
            path: '/me',
            component: User,
            props: true,
        },

        // gestisce i "404", !!! DEVE STARE PER ULTIMA !!!
        {
          path: '*',
          name: 'not-found',
          component: NotFound,
          props: {
            prerenderStatusCode: '404',
          }
        },
    ],
})

router.beforeEach((to, from, next) => {

    //window.scrollTo(0, 0);
    //window.previousUrl = from.path

    // se devo andare in manutenzione non controllare nient'altro: vai in manutenzinoe
    if (to.name == 'maintenance')
    {
      next()
      return
    }

    let route = router.options.routes.filter(function (r) {
        return r.name == to.matched[0].name
    })[0]

    // se c'è il token nell'url, proseguo la navigazione
    if(to.query.token)
    {
      Utilities.methods.mixinSendMutation('setToken',to.query.token)
      next()
      return
    }

    // se la rotta è pubblica, proseguo la navigazione
    if(route.publicRoute == true)
    {
      next()
      return
    }

    // se la rotta NON è pubblica, controllo che ci sia un token
    else
    { 
      // se c'è il token, proseguo la navigazione
      if(Utilities.methods.mixinIsAuthenticated())
      {
          next()
          return
      }
      
      // se NON c'è il token, redirect da qualche parte
      Utilities.methods.mixinGoTo('how-it-works',{})
    }
})

router.afterEach((to) => {
  
    // quando torno indietro con history back, nella home ci sono molti componenti caricati in modo asincrono: il browser "ricorda" che deve tornare indietro a (esempio) 1500px di scroll verticale, e lo fa
    // la window però, che a pieno caricamento di tutte le componenti era alta magari 3000px, ora magari è alta solo 800px in attesa di tutti gli slider
    // il risultato è che, senza window.scrollTo(0,0), ad ogni history back la home viene scrollata al footer (cioè al massimo scroll verticale consentito alla window in quel momento)
    // quando poi le componenti asincrone vengono caricate, la pagina resta sul footer
    // TODO: cachare gli insalabam per evitarne il caricamento ogni volta che si torna indietro
    // TODO: se si vuole scrollare ad un preciso componente bisogna salvarlo nello state
    if(to.name == "homepage")
    {
        setTimeout(function(){
            window.scrollTo(0,0)
        },100)
    }

    // per scrollare alle sezioni di id #id
    if (to.hash && to.hash != '') {
        if (process.env.VUE_APP_MODE != 'production') window.console.log('fragment found')
        setTimeout(function () {
            if (process.env.VUE_APP_MODE != 'production') window.console.log('scrolling to ' + to.hash)
            document.getElementById(to.hash.substr(1)).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 250);
    }

})


export default router