<template>
    <div :class="'page-'+this.$route.name">
        <div id="app" class="maintenance" v-if="this.$route.name == 'maintenance'">
            <router-view/>
        </div>

        <div id="app" v-else>
            <div :v-bind="config" class="config-wrapper">

                <b-alert :show="VUE_APP_MODE !== 'production'" id="debug-alert">
                    <b>SITO DEMO</b>
                    <br>
                    vai su <a :href="prodUrl">{{ prodName }}</a>
                </b-alert>

                <div v-if="Object.keys(config).length !== 0">
                    <Customize />
                    <Header v-if="this.$route.name !== 'custom-landing-dc'" />
                    <main>
                        <router-view/>
                    </main>
                    <PageFooter v-if="this.$route.name !== 'custom-landing-dc'" />
                </div>

                <b-container fluid v-else class="text-center">
                    <br><br>
                    <SvgLogo />
                    <br>
                    <Spinner />
                    <p>
                        Tutta la tranquillità di prenotare con {{ siteTitle }}
                    </p>
                    <br>
                    <PreFooterWhite />
                </b-container>
            </div>

            <!-- notification modal -->
            <ModalNotification ref="notification"/>

            <!--<StickyBanner v-if="VUE_APP_NAME == 'ambalagi' && config.guiSettings && config.guiSettings.integrations && config.guiSettings.integrations.scalapay.enabled && config.guiSettings.integrations.scalapay.showPromo" />-->
            <StickyBanner v-if="config.stickybanner"
              :sticky="config.stickybanner"
            />
        </div>
    </div>
</template>

<script>
    import Customize from '@/components/customize'
    import StickyBanner from '@/components/blocks/sticky-banner'
    export default {
        name: 'app',
        metaInfo: {
            title: process.env.VUE_APP_SITE_PAYOFF,
            titleTemplate: '%s - ' + process.env.VUE_APP_SITE_TITLE,
            meta: [
                { vmid: 'metaTitle', name: 'Title', content: 'Prenota online hotel, beb, appartamenti. Semplice e sicuro!' },
                { vmid: 'metaDescription', name: 'Description', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
                { vmid: 'metaKeywords', name: 'Keywords', content: process.env.VUE_APP_SITE_TITLE + ', viaggi online, online travel booking, online travel agency, agenzia viaggi online, prenotazione online viaggi, viaggi welfare aziendale, viaggi welfare, viaggi promozioni, viaggi marketing, viaggi viaggi programmi fedeltà, viaggi programma fedeltà, viaggi loyalty program, catalogo a premi viaggi' },
                { vmid: 'metaAbstract', name: 'abstract', content: process.env.VUE_APP_SITE_TITLE + 'è un servizio di prenotazione viaggi online pensato per gli utenti del welfare aziendale. ' + process.env.VUE_APP_SITE_TITLE + ' offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
                { vmid: 'metaClassification', name: 'Classification', content: 'Commercial' },
                { vmid: 'metaResourceType', name: 'resource-type', content: 'document' },
                { vmid: 'metaDistribution', name: 'Distribution', content: 'Global' },
                { vmid: 'metaRating', name: 'Rating', content: 'General' },
                { vmid: 'metaLanguage', name: 'Language', content: 'IT' },
                { vmid: 'metaOwner', name: 'Owner', content: 'Salabam SRL' },
                { vmid: 'ogTitle', property: 'og:title', content: 'Prenota online in tempo reale hotel, beb, appartamenti e ville' },
                { vmid: 'ogSiteName', name: 'site_name', content: process.env.VUE_APP_SITE_TITLE + ' live booking' },
                { vmid: 'ogType', name: 'og:type', content: 'website' },
                { vmid: 'ogUrl', name: 'og:url', content: window.location.href },
                { vmid: 'ogImage', name: 'og:image', content: process.env.VUE_APP_SITE_HOST + 'assets/' + process.env.VUE_APP_OG_IMAGE },
                { vmid: 'ogImageWidth', name: 'og:image:width', content: '512' },
                { vmid: 'ogImageHeight', name: 'og:image:height', content: '512' },
                { vmid: 'ogDescription', name: 'og:description', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
                { vmid: 'dcTitle', name: 'DC.title', lang: 'it', content: 'Prenota online i tuoi viaggi in modo semplice e soprattutto sicuro. ' + process.env.VUE_APP_SITE_TITLE + ', soluzioni di travel booking per il welfare aziendale, per i piani loyalty, per il marketing i concorsi e le promozioni' },
                { vmid: 'dcCreator', name: 'DC.creator', content: 'Salabam SRL' },
                { vmid: 'dcSubject', name: 'DC.subject', lang: 'it', content: process.env.VUE_APP_SITE_TITLE + ';viaggi online;online travel booking;online travel agency;agenzia viaggi online;prenotazione online viaggi;viaggi welfare aziendale;viaggi welfare;viaggi promozioni;viaggi marketing;viaggi viaggi programmi fedeltà;viaggi programma fedeltà;viaggi loyalty program;catalogo a premi viaggi' },
                { vmid: 'dcDescription', name: 'DC.description', lang: 'it', content: process.env.VUE_APP_SITE_TITLE + 'è un servizio di prenotazione viaggi online pensato per gli utenti del welfare aziendale. ' + process.env.VUE_APP_SITE_TITLE + ' offre soluzioni di online travel booking per i programmi fedeltà, per le promozioni e i concorsi' },
                { vmid: 'dcType', name: 'DC.type', scheme: 'DCTERMS.DCMIType', content: 'Text' },
                { vmid: 'dcFormat', name: 'DC.format', content: 'text/html' },
                { vmid: 'dcIdentifier', name: 'DC.identifier', scheme: 'DCTERMS.URI', content: process.env.VUE_APP_SITE_HOST },
                { vmid: 'dcLanguage', name: 'DC.language', scheme: 'DCTERMS.RFC1766', content: 'IT' },
                { vmid: 'robots', name: 'robots', content: process.env.VUE_APP_META_ROBOTS },
            ]
        },
        components: {
            Customize,
            StickyBanner,
            SvgLogo: () => import('@/components/' + process.env.VUE_APP_CUSTOM_SRC + 'svg/logo')
                .then(function(SvgLogo) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: SvgLogo Component loaded')
                    return SvgLogo
                }),
            Header: () => import(('./components/blocks/header/header'))
                .then(function(Header) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Header Component loaded')
                    return Header
                }),                
            PageFooter: () => import('./components/blocks/page-footer')
                .then(function(PageFooter) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: PageFooter Component loaded')
                    return PageFooter
                }),
            PreFooterWhite: () => import('./components/blocks/common/prefooter-white')
                .then(function(PreFooterWhite) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: PreFooterWhite Component loaded')
                    return PreFooterWhite
                }),
            Spinner: () => import('./components/atoms/spinner')
                .then(function(Spinner) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: Spinner Component loaded')
                    return Spinner
                }),
            ModalNotification: () => import('./components/blocks/modal-notification')
                .then(function(ModalNotification) {
                    if(process.env.VUE_APP_MODE != 'production') window.console.log('async: ModalNotification Component loaded')
                    return ModalNotification
                }),
        },
        data() {
            return {
                config: {},
                node_env: process.env.NODE_ENV,
                VUE_APP_MODE: process.env.VUE_APP_MODE,
                VUE_APP_NAME: process.env.VUE_APP_NAME,
                siteTitle: process.env.VUE_APP_SITE_TITLE,
                prodUrl: process.env.VUE_APP_PROD_URL,
                prodName: process.env.VUE_APP_PROD_NAME
            }
        },
        beforeMount() {
            let _this = this

            // aspetto che il router carichi la rotta attuale, altrimenti appConfig non sa ancora in che rotta mi trovo
            _this.waitRouter()
                .then(function(){
                    _this.appConfig()
                        .then(value => {
                            _this.config = value
                        })
                })
        },
        mounted()
        {
            let _this = this
                window.eventBus.$on('gotNotification', function(data) { 
                _this.$refs.notification.handleNotification(data)
            })
        },
        created () {
            this.handleScroll()
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            waitRouter()
            {
                let _this = this

                return new Promise(function (resolve) {
                    var git = setInterval(function () {
                        let route = _this.$route.name
                        if (route !== null) {
                            clearInterval(git);
                            resolve('done');
                        }
                    }, 100);
                });
            },
            handleScroll () {
                if(window.top.scrollY)
                {
                    document.body.classList.add('scrolling')
                }
                else
                {
                    document.body.classList.remove('scrolling')
                }
            }
        }
    }
</script>

<style lang="scss">
    @import 'scss/_app.scss';
</style>